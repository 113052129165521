import { Module } from 'vuex';
import { RootState } from 'qs_vuetify/src/types/states';

import { EventsViewState } from '@/types/states';

export const eventsViewState: Module<EventsViewState, RootState> = {
  namespaced: true,
  state: {
    calendarType: 'week',
    calendarValue: '',
    contactsOptions: {
      sortBy: ['id'],
      sortDesc: [false],
      page: 1,
      itemsPerPage: 10,
    },
    contactsParams: {
      page: 1,
      per_page: 20,
    },
    params: {},
    selectedItems: [],
    showAlerts: true,
    showExtraInformation: true,
  },
  getters: {
    calendarType(state) {
      return state.calendarType;
    },
    calendarValue(state) {
      return state.calendarValue;
    },
    contactsOptions(state) {
      return state.contactsOptions;
    },
    contactsParams(state) {
      return state.contactsParams;
    },
    params(state) {
      return state.params;
    },
    selectedItems(state) {
      return state.selectedItems;
    },
    showAlerts(state) {
      return state.showAlerts;
    },
    showExtraInformation(state) {
      return state.showExtraInformation;
    },
  },
  mutations: {
    addSelectedItem(state, selectedItem) {
      if (!state.selectedItems.find((i) => i.id === selectedItem.id)) {
        state.selectedItems.push({
          ...selectedItem,
        });
      }
    },
    removeSelectedItem(state, selectedItem) {
      const index = state.selectedItems.findIndex((i) => i.id === selectedItem.id);
      if (index !== -1) {
        state.selectedItems.splice(index, 1);
      }
    },
    setCalendarType(state, calendarType) {
      state.calendarType = calendarType;
    },
    setCalendarValue(state, calendarValue) {
      state.calendarValue = calendarValue;
    },
    setContactsOptions(state, contactsOptions) {
      state.contactsOptions = contactsOptions;
    },
    setContactsParams(state, contactsParams) {
      state.contactsParams = contactsParams;
    },
    showAlerts(state, showAlerts) {
      state.showAlerts = showAlerts;
    },
    showExtraInformation(state, showExtraInformation) {
      state.showExtraInformation = showExtraInformation;
    },
    setParams(state, params) {
      state.params = params;
    },
    setSelectedItems(state, selectedItems) {
      state.selectedItems.splice(
        0,
        state.selectedItems.length,
        ...selectedItems,
      );
    },
  },
};

export default eventsViewState;
