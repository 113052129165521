import axios from 'axios';
import {
  ActionTree,
  GetterTree,
  Module,
  MutationTree,
} from 'vuex';

import { MailCampaign } from 'qs_vuetify/src/types/models';
import { RootState } from 'qs_vuetify/src/types/states';

import { MailCampaignsRestState } from '@/types/states';

import {
  buildRestActions,
  buildRestGetters,
  buildRestMutations,
  buildRestState,
} from 'qs_vuetify/src/plugins/rest';

export const mailCampaignsState: MailCampaignsRestState = {
  ...buildRestState<MailCampaign>('mail_campaigns', {}),
  filters: [],
  filtersLoaded: false,
  stats: null,
};

export const getters: GetterTree<MailCampaignsRestState, RootState> = {
  ...buildRestGetters<MailCampaign, MailCampaignsRestState>(),
  filters(state) {
    return state.filters;
  },
  filtersLoaded(state) {
    return state.filtersLoaded;
  },
  options(state) {
    return state.options;
  },
  stats(state) {
    return state.stats;
  },
};

export const actions: ActionTree<MailCampaignsRestState, RootState> = {
  ...buildRestActions<MailCampaign, MailCampaignsRestState>(),
  async filters({ commit }, params) {
    commit('filtersLoaded', false);

    const ajax = axios.get('/filters', {
      params: {
        for: 'lists',
        per_page: '*',
        repository: 'MailCampaign',
        fields: 'name,filter,private,instance_id',
        ...params,
      },
    });

    const {
      data: {
        data: filters,
      },
    } = await ajax;

    commit('filters', filters);
    commit('filtersLoaded', true);
  },
  async send({ commit }, id) {
    commit('loaded', false);
    commit('error', null);

    try {
      const ajax = axios.put(`/mail_campaigns/${id}/send`);

      commit('ajax', ajax);

      await ajax;
    } catch (err) {
      if (err.response && err.response.data) {
        commit('error', err.response.data);
      }

      throw err;
    } finally {
      commit('ajax', null);
    }

    commit('loaded', true);
  },
  async sendTest({ commit }, id) {
    commit('loaded', false);
    commit('error', null);

    try {
      const ajax = axios.post(`/mail_campaigns/${id}/send_test`);

      commit('ajax', ajax);

      await ajax;
    } catch (err) {
      if (err.response && err.response.data) {
        commit('error', err.response.data);
      }

      throw err;
    } finally {
      commit('ajax', null);
    }

    commit('loaded', true);
  },
  async stats({ commit }, { id }) {
    const ajax = axios.get(`/mail_campaigns/${id}/stats`);

    const {
      data: stats,
    } = await ajax;

    commit('stats', stats);
  },
};

export const mutations: MutationTree<MailCampaignsRestState> = {
  ...buildRestMutations<MailCampaign, MailCampaignsRestState>(),
  filtersLoaded(state, filtersLoaded) {
    state.filtersLoaded = filtersLoaded;
  },
  filters(state, filters) {
    state.filters = filters;
  },
  options(state, options) {
    state.options = options;
  },
  stats(state, stats) {
    state.stats = stats;
  },
};

export const mail_campaigns: Module<MailCampaignsRestState, RootState> = {
  namespaced: true,
  state: mailCampaignsState,
  getters,
  actions,
  mutations,
};
