import Vue from 'vue';
import { RouteConfig } from 'vue-router';

const NationalEventDelegation = () => import(/* webpackChunkName: "NationalEvent" */'@/views/NationalEvents/NationalEventDelegation.vue');

const nationalEvents: RouteConfig[] = [
  {
    path: '/national/:id/delegation',
    props: true,
    name: 'NationalEvent',
    component: NationalEventDelegation,
    meta: {
      data: {
        national_events: {
          retrieve: {
            conditional(vm: Vue) {
              return !vm.$store.state.national_events.item
                || vm.$store.state.national_events.item.id !== Number.parseInt(vm.$route.params.id, 10);
            },
            contextual(vm: Vue) {
              return {
                id: vm.$route.params.id,
              };
            },
          },
        },
        national_event_participations: {
          index: {
            contextual(vm: Vue) {
              return {
                instance_id: vm.$store.getters['auth/instanceId'],
                national_event_id: vm.$route.params.id,
              };
            },
            params: {
              fields: '*,contact.searchable_text,contact.full_name,contact.gender,contact.v1_contact_id,contact.email,contact.user.id,contact.status',
              order: 'contact.full_name',
              per_page: -1,
            },
          },
        },
      },
      menus: [],
      requires: ['NATIONAL_EVENTS_RETRIEVE', 'NATIONAL_EVENT_PARTICIPATIONS_RETRIEVE'],
      title: 'Inscription de délégation',
    },
  },
];

export default nationalEvents;
