/* eslint-disable comma-dangle */
import Vue from 'vue';
import AuthenticationMixin from 'qs_vuetify/src/mixins/AuthenticationMixin';
import { RouteConfig } from 'vue-router';

const MailCampaign = () => import(
  /* webpackChunkName: "MailCampaign" */
  '@/views/MailCampaigns/MailCampaign.vue'
);
const MailCampaigns = () => import(
  /* webpackChunkName: "MailCampaigns" */
  '@/views/MailCampaigns/MailCampaigns.vue'
);
const NewMailCampaignDialog = () => import(
  /* webpackChunkName: "NewMailCampaignDialog" */
  '@/views/MailCampaigns/NewMailCampaignDialog.vue'
);

const mailCampaigns: RouteConfig[] = [
  {
    path: '/mail_campaigns',
    name: 'MailCampaigns',
    component: MailCampaigns,
    meta: {
      data: {
        mail_campaigns: {
          filters: {
            conditional(vm: Vue) {
              return !vm.$store.state.mail_campaigns.filtersLoaded;
            },
            contextual(vm: Vue) {
              return {
                accessible_from: vm.$store.state.auth.instanceId,
              };
            },
          },
          index: {
            conditional(vm: Vue) {
              return !vm.$store.state.mail_campaigns.lastLoadedAt;
            },
            prefix(vm: Vue) {
              return `/instances/${vm.$store.state.auth.instanceId}`;
            },
            params: {
              fields: [
                'body',
                'created_at',
                'created_by_user.contact_name',
                'deleted_at',
                'deleted_by_user',
                'end_at',
                'filter',
                'id',
                'instance.email',
                'instance.name',
                'name',
                'sent_at',
                'sent_by_user.contact_name',
                'start_at',
                'stats',
                'status',
                'subject',
                'updated_at',
                'updated_by_user.contact_name',
              ].join(','),
            },
          },
        },
      },
      menus: ['primary'],
      requires: ['MAIL_CAMPAIGNS_RETRIEVE'],
      title: 'Courriels',
    },
    children: [
      {
        path: 'new',
        name: 'NewMailCampaignDialog',
        component: NewMailCampaignDialog,
        props: (route) => ({
          id: 'new',
          type: route.params?.type,
        }),
        meta: {
          auth: true,
          data: {
            contacts: {
              filters: {
                conditional(vm: Vue) {
                  return !vm.$store.state.contacts.filtersLoaded;
                },
                contextual(vm: Vue) {
                  return {
                    accessible_from: vm.$store.state.auth.instanceId,
                  };
                },
              },
            },
            mail_campaigns: {
              loadEmpty: {},
            },
            objectives: {
              loadEmpty: {
                conditional(vm: Vue & AuthenticationMixin) {
                  return vm.userHas('OBJECTIVES_CREATE');
                },
              },
            },
          },
          menus: [],
          name: 'Nouvelle campagne courriels',
        },
      },
    ],
  },
  {
    path: '/mail_campaigns/:id',
    name: 'MailCampaign',
    component: MailCampaign,
    props: true,
    meta: {
      data: {
        campaign_contacts: {
          loadEmpty: {},
        },
        contacts: {
          index: {
            prefix(vm: Vue) {
              return `/mail_campaigns/${vm.$route.params.id}`;
            },
          },
        },
        mail_campaigns: {
          retrieve: {
            contextual(vm: Vue) {
              return {
                id: vm.$route.params.id,
              };
            },
            params: {
              fields: '*',
              with_deleted: true,
            },
          },
        },
      },
      lockInstance: true,
      menus: [],
      title: 'Campagne courriel',
    },
  },
];

export default mailCampaigns;
