<template>
  <v-app :class="`breakpoint-${$vuetify.breakpoint.name}`">
    <qs-layout-navigation
      :admin-menu="adminMenuItems"
      :help="routeHelp"
      :instances="userInstances"
      :lock-instance="lockInstance"
      :primary-menu="primaryMenuItems"
      version-link="https://docs.google.com/document/d/1kZIBfWtRB1lb4nl_7VOD1pQkkMG7yejJJ-nkr6FR7aE/edit?usp=sharing"
      :version-number="version"
      @acceptedConfidentialityAgreement="redirectOrReload"
      @instance="setInstanceId"
      @login="loadUserAndSetInitialInstance"
      @logout="redirectToHome"
    >
      <template #loginDialogLinks>
        <a class="white--text" :href="newPasswordLink">
          Mot de passe oublié?
        </a>
      </template>
    </qs-layout-navigation>

    <v-main>
      <qs-layout-notifications />

      <router-view />

      <qs-confirmation-modal
        v-bind="confirmationDialog"
        @click:confirm="runConfirmationDialogCallback"
        @click:cancel="resetConfirmationDialog"
      />
    </v-main>
  </v-app>
</template>

<script lang="ts">
import Component, { mixins } from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { Action, Getter, Mutation } from 'vuex-class';

import QsConfirmationModal from 'qs_vuetify/src/components/Dialog/QsConfirmationModal.vue';
import QsLayoutNavigation from 'qs_vuetify/src/components/Layout/QsLayoutNavigation.vue';
import QsLayoutNotifications from 'qs_vuetify/src/components/Layout/QsLayoutNotifications.vue';

import AuthenticationMixin from 'qs_vuetify/src/mixins/AuthenticationMixin';

import { ButtonProps, ConfirmationDialog, MenuItem } from 'qs_vuetify/src/types/components';
import { Instance } from 'qs_vuetify/src/types/models';

import { routes } from '@/router/index';

const instanceFields = [
  'id',
  'name',
];
export const userFields = [
  'id', 'email', 'superadmin', 'contact_id', 'contact_name',
  'favorites.id', 'unread_notifications',
  'default_instance_id', 'accepted_confidentiality_agreement',
  'instance_users_expiring_soon', 'instance_users_expired_recently',
  ...instanceFields.map((f) => `instances.${f}`),
  ...instanceFields.map((f) => `inherited_instances.${f}`),
].join(',');

@Component({
  components: {
    QsConfirmationModal,
    QsLayoutNavigation,
    QsLayoutNotifications,
  },
})
export default class App extends mixins(AuthenticationMixin) {
  @Action('checkUser', { namespace: 'auth' }) checkUser!: any;
  @Getter('instanceId', { namespace: 'auth' }) instanceId!: number | null;
  @Getter('lastInstanceId', { namespace: 'auth' }) lastInstanceId!: number | null;
  @Action('loadInstance', { namespace: 'auth' }) loadInstance!: any;
  @Action('loadUser', { namespace: 'auth' }) loadUser!: any;
  @Action('logout', { namespace: 'auth' }) logout!: any;
  @Getter('token', { namespace: 'auth' }) token!: string | null;
  @Getter('ajax', { namespace: 'auth' }) userLoading!: Promise<any> | null;
  @Mutation('instanceId', { namespace: 'auth' }) setInstanceId!: any;

  @Getter('confirmationDialog', { namespace: 'global' }) confirmationDialog!: ConfirmationDialog;
  @Mutation('resetConfirmationDialog', { namespace: 'global' }) resetConfirmationDialog!: any;
  @Mutation('runConfirmationDialogCallback', { namespace: 'global' }) runConfirmationDialogCallback!: any;

  get primaryMenuItems(): Array<MenuItem> {
    return routes
      .filter((r) => r.meta && r.meta.menus)
      .map((r) => ({
        ...r.meta,
        route: r.name,
      }))
      .filter((i) => i.menus?.indexOf('primary') !== -1)
      .filter((i) => this.userHasAll(i.requires || []));
  }

  get routeHelp(): ButtonProps | null {
    if (this.$route.meta && this.$route.meta.help) {
      return {
        color: 'info',
        href: this.$route.meta.help,
      };
    }

    return null;
  }

  get adminMenuItems(): Array<MenuItem> {
    if (!this.userIsConnected) {
      return [];
    }

    return [
      {
        title: 'Changements de CoCo',
        route: process.env.VUE_APP_ROLES_CHANGE_URL,
        target: '_blank',
        requires: [],
      },
      // RETRAIT DE CET ÉLÉMENT DE MENU HORS DE LA PÉRIODE ÉLECTORALE
      /* {
        title: 'Déclarer votre comité électoral',
        route: process.env.VUE_APP_ELECTORAL_COMMITTEE_URL,
        target: '_blank',
        requires: [],
      }, */
      {
        title: 'Profil',
        route: process.env.VUE_APP_PROFIL_URL,
        target: '_blank',
        requires: [],
      },
    ];
  }

  // eslint-disable-next-line class-methods-use-this
  get newPasswordLink(): string {
    return `${process.env.VUE_APP_PROFIL_URL}/demande_nouveau_mot_de_passe`;
  }

  get version(): string {
    return this.$store.state.version;
  }

  async mounted() {
    await this.loadUserAndSetInitialInstance();
  }

  async loadUserAndSetInitialInstance() {
    this.$store.commit('global/confidentialityAgreementDialog', false);

    const {
      fullPath,
      name: routeName,
    } = this.$route;

    if (!this.user) {
      if (this.token) {
        try {
          await this.loadUser(userFields);

          this.setUserInstance();
        } catch (e) {
          if (e.response.status === 401) {
            await this.logout();

            if (routeName !== 'Home') {
              this.redirectToHome(fullPath);
            }

            this.$store.commit('global/loginDialog', true);
            return;
          }
        }
      } else {
        if (fullPath !== '/') {
          this.redirectToHome(fullPath);
        }

        this.$store.commit('global/loginDialog', true);
        return;
      }
    } else {
      try {
        await this.checkUser([
          'id',
          'superadmin',
          'instance_users_expiring_soon',
          'instance_users_expired_recently',
          'default_instance_id',
        ].join(','));

        this.setUserInstance();

        this.onUserIsConnectedChanged(true);
      } catch (e) {
        await this.logout();

        if (routeName !== 'Home') {
          this.redirectToHome(fullPath);
        }

        this.$store.commit('global/loginDialog', true);
        return;
      }
    }

    if (this.user) {
      if (!this.instanceId && this.user?.instances && this.user.instances.length > 0) {
        this.setInstanceId(this.user.instances[0].id);
      } else if (
        this.user?.inherited_instances
          && this.user.inherited_instances.map((i) => i.id).includes(this.instanceId)) {
        // Initialiser les préfixes
        this.onInstanceIdChanged(this.instanceId, null);
      } else if (this.user?.instances && this.user.instances.length > 0) {
        this.setInstanceId(this.user.instances[0].id);
      }

      if (!this.user.accepted_confidentiality_agreement) {
        if (routeName !== 'Home') {
          this.redirectToHome(fullPath);
        }

        this.$store.commit('global/confidentialityAgreementDialog', true);
      }
    }
  }

  get lockInstance(): boolean {
    return !!this.$route?.meta?.lockInstance;
  }

  get userInstances(): Array<Instance> {
    return this.user?.inherited_instances || [];
  }

  redirectToHome(redirect: string | null = null) {
    this.$router.push(`/${redirect ? `?r=${encodeURIComponent(redirect)}` : ''}`);
  }

  async redirectOrReload() {
    if (this.$route?.query?.r && typeof this.$route?.query?.r === 'string') {
      this.$router.replace(this.$route.query?.r);
      return;
    }

    document.location.reload();
  }

  @Watch('instanceId')
  async onInstanceIdChanged(newInstanceId: number | null, oldInstanceId: number | null) {
    const slugs = ['call_campaigns', 'contacts', 'events', 'mail_campaigns', 'tags', 'volunteers'];
    if (newInstanceId) {
      slugs.forEach((slug) => {
        this.$store.commit(`${slug}/prefix`, `/instances/${newInstanceId}`);
        this.$store.commit(`${slug}/data`, []);
        this.$store.commit(`${slug}/loaded`, false);
        this.$store.commit(`${slug}/lastLoadedAt`, null);
        this.$store.commit(`${slug}/total`, null);
      });

      if (oldInstanceId) {
        this.$store.commit('contacts/filtersLoaded', false);
        this.$store.commit('contacts/filters', []);

        this.$store.commit('volunteers/stats', null);
        this.$store.commit('volunteers/statsLoaded', false);
      }

      if (this.user?.accepted_confidentiality_agreement) {
        this.$store.dispatch('globalView/tags', {
          accessible_from: newInstanceId,
          fields: 'description,instance.name,name',
          order: 'name',
          per_page: -1,
        });
      }

      if (newInstanceId) {
        if (!this.user?.id) {
          await this.userLoading;
        }

        await this.loadInstance({
          id: newInstanceId,
          direct: (this.user?.instances || []).findIndex((i) => i.id === newInstanceId) !== -1,
          fields: 'id,name,rights_slugs,active_volunteers.contact_id,meta',
        });
      }
    } else {
      slugs.forEach((slug) => {
        this.$store.commit(`${slug}/prefix`, '');
      });
    }
  }

  @Watch('userIsConnected')
  onUserIsConnectedChanged(connected: boolean) {
    if (connected) {
      if (this.$route.query && this.$route.query.r) {
        this.$router.push(`${this.$route.query.r}`);
      }

      if (this.userHas('CALL_CAMPAIGNS_CREATE')) {
        this.$store.dispatch('call_campaigns/loadEmpty');
      }

      if (this.userHas('MAIL_CAMPAIGNS_CREATE')) {
        this.$store.dispatch('mail_campaigns/loadEmpty');
      }

      if (this.userHas('EVENTS_CREATE')) {
        this.$store.dispatch('events/loadEmpty');
      }
    } else if (this.$route.fullPath !== '/') {
      this.$router.push('/');
    }
  }

  setUserInstance(): number | null {
    if (!!this.user && !!this.user.inherited_instances && this.user.inherited_instances.length > 0) {
      if (this.lastInstanceId
        && this.user.inherited_instances.find((i) => i.id === this.lastInstanceId)) {
        this.$store.commit('auth/instanceId', this.lastInstanceId);
        return this.lastInstanceId;
      }

      if (this.user.default_instance_id) {
        this.$store.commit('auth/instanceId', this.user.default_instance_id);
        return this.user.default_instance_id;
      }

      this.$store.commit('auth/instanceId', this.user.inherited_instances[0].id);
      return this.user.inherited_instances[0].id;
    }

    if (this.user && this.user.instances && this.user.instances.length > 0) {
      if (this.lastInstanceId && this.user.instances.find((i) => i.id === this.lastInstanceId)) {
        this.$store.commit('auth/instanceId', this.lastInstanceId);
        return this.lastInstanceId;
      }

      if (this.user.default_instance_id) {
        this.$store.commit('auth/instanceId', this.user.default_instance_id);
        return this.user.default_instance_id;
      }

      this.$store.commit('auth/instanceId', this.user.instances[0].id);
      return this.user.instances[0].id;
    }

    this.$store.commit('auth/instanceId', null);
    return null;
  }
}
</script>
